.header-container {
  border-bottom: 1px solid beige;
  padding: 1rem;
  font-family: "Nanum Brush Script", cursive;
}

.header-logo {
  font-size: 5rem;
}

.header-logo-sub {
  font-size: 2rem;
  margin-top: -1rem;
  padding-left: 0.25rem;
}

.footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
}

.footer {
  width: 100%;
  border-top: 1px solid beige;
  display: flex;
  justify-content: end;
  align-items: center;
}

.footer p {
  margin-right: 1rem;
}

@media only screen and (min-width: 768px) {
  .header-container {
    width: 80vw;
    margin: 0 10vw;
  }

  .footer {
    /*width: 80%;*/
    margin: 0 10vw;
  }

  .footer p {
    margin-right: 0;
  }
}

.footer-text {
  color: beige;
  animation-name: text-glow;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  font-size: 1.4rem;
}

@keyframes text-glow {
  0% {
    color: #282c34;
  }
  50% {
    color: beige;
  }
  100% {
    color: #282c34;
  }
}
